import React from "react"
import { makeStyles } from '@material-ui/styles';

import { useStaticQuery, graphql, Link } from "gatsby"
import Grid from '@material-ui/core/Grid';
import Img from "gatsby-image"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';

const useStyles = makeStyles(theme  => ({
  root: {
    padding: theme.spacing(2),
    color: 'white',
    backgroundColor: theme.palette.primary.dark,
    flexGrow: 1,
    fontSize: '1.1rem',
  },
  col: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  copyright: {
    padding: theme.spacing(2),
    textAlign: 'center',
    fontSize: '1rem',
  },
  regularLink: {
    color: 'white',
    textDecoration: 'none'
  },
  social: {

  }
}));

const Footer = () => {
  const classes = useStyles();

  const mapImage = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "map.png" }) {
        childImageSharp {
          fixed(width: 190) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <footer className={classes.root}>

    <Grid container spacing={3} justify="center">

      <Grid item xs={12} md={4}>
        <div className={classes.col}>
            <b>Address:</b>
            <address>
              1384 S. Broadway<br />
              Denver, Colorado 80210
            </address>
            <br /><br />
            <b>Phone:</b><br />
            <OutboundLink href="tel:7203793131" className={classes.regularLink}>(720) 379-3131</OutboundLink>
        </div>
      </Grid>
      <Grid item xs={12} md={4}>
        <div className={classes.col}>
          <b>Hours:</b><br />
          Mon - Thurs: 11am-9pm<br />
          Fri - Sat: 11am-10pm<br />
          Sun: 11-8
          <br />
          <OutboundLink href="https://www.facebook.com/theclucktruck" target="_blank" className={classes.regularLink}><FacebookIcon /></OutboundLink>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <OutboundLink href="https://twitter.com/theclucktruckco" target="_blank" className={classes.regularLink}><TwitterIcon /></OutboundLink>
        </div>
      </Grid>
      <Grid item xs={12} md={4}>
        <div className={classes.col}>
            <Link to="/location"><Img fixed={mapImage.placeholderImage.childImageSharp.fixed} /></Link>
        </div>
      </Grid>

      <Grid item xs={12}>
          <div className={classes.copyright}>
            © {new Date().getFullYear()}, Site by {` `}
            <OutboundLink href="https://SummitCreative.io" target="_blank" style={{ color: 'white' }}>Summit Creative</OutboundLink>
          </div>
      </Grid>
    </Grid>
  </footer>

)};



export default Footer
