import React from "react"
import PropTypes from "prop-types"

import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Theme from './theme'
import Box from '@material-ui/core/Box';

import Header from "./components/header"
import Footer from "./components/footer"

const Layout = ({ children }) => {

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={Theme}>
        <Box>
          <Header />
          {children}
          <Footer />
        </Box>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
