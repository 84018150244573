import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StaticQuery, graphql, Link } from "gatsby"

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { OutboundLink } from 'gatsby-plugin-google-analytics'

// Icons
import logoSVG from '../images/logo.svg'
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  titleLink: {
    color: 'white', 
    textDecoration: 'none', 
    fontFamily: 'anton,Helvetica Neue,Helvetica,Open Sans,Arial,sans-serif'
  }
}));

export function PureHeader({siteTitle}) {
  const classes = useStyles();
  const largeScreen = useMediaQuery('(min-width:600px)');

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="/menu/">
          <ListItemText primary="Menu" />
        </ListItem>
        <ListItem button component={Link} to="/catering/">
          <ListItemText primary="Catering" />
        </ListItem>
        <ListItem button component={Link} to="/about-us/">
          <ListItemText primary="About Us" />
        </ListItem>
        <ListItem button component={Link} to="/location/">
          <ListItemText primary="Location" />
        </ListItem>
      </List>
    </div>
  );

  const menuButtons = () => {
    if (largeScreen){
      return (
        <>
          <Button component={Link} to="/menu/" color="inherit">Menu</Button>
          <Button component={Link} to="/location/" color="inherit">Location</Button>
          <Button component={Link} to="/about-us/" color="inherit">About Us</Button>
          <Button component={Link} to="/catering/" color="inherit">Catering</Button>
        </>
      );
    } 
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          {!largeScreen &&
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer('left', true)}>
              <MenuIcon />
            </IconButton>
          }
          <Typography variant="h6" className={classes.title} >
            <Link to="/" color="inherit" className={classes.titleLink}>
              <img src={logoSVG} alt="" style={{height: '25px', marginRight: "0.5rem"}}/> Cluck Chicken
            </Link> 
          </Typography>
          {menuButtons()}
        </Toolbar>
      </AppBar>
      
      <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
        {sideList('left')}
      </Drawer>
    </div>
  );
}

export const Header = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <PureHeader {...props} siteTitle={data.site.siteMetadata.title} />}
  />
)

export default Header
