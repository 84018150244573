/*
   Material Theme Overrides. 
   See: https://material-ui.com/customization/palette/
*/

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#222'
    },
  },
  typography: {
    h1: { fontFamily: ['anton', 'Helvetica Neue', 'Helvetica', 'Open Sans', 'Arial', 'sans-serif'].join(','), textTransform: 'uppercase' },
    h2: { fontFamily: ['Helvetica Neue', 'Helvetica', 'Open Sans', 'Arial', 'sans-serif'].join(',') },
    h3: { fontFamily: ['Helvetica Neue', 'Helvetica', 'Open Sans', 'Arial', 'sans-serif'].join(',') },
    h4: { fontFamily: ['Helvetica Neue', 'Helvetica', 'Open Sans', 'Arial', 'sans-serif'].join(',') },
    h5: { fontFamily: ['Helvetica Neue', 'Helvetica', 'Open Sans', 'Arial', 'sans-serif'].join(',') },
    h6: { fontFamily: ['Helvetica Neue', 'Helvetica', 'Open Sans', 'Arial', 'sans-serif'].join(',') },
  },
});

theme = responsiveFontSizes(theme);

export default theme
